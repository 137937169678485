import { default as aboutwDaWN0gPCNMeta } from "/home/webpages/hochwacht-frontend/pages/about.vue?macro=true";
import { default as _91id_93x60bGA2kemMeta } from "/home/webpages/hochwacht-frontend/pages/agegroups/[id].vue?macro=true";
import { default as contactjzGzDS0guuMeta } from "/home/webpages/hochwacht-frontend/pages/contact.vue?macro=true";
import { default as documentsw9Wj6wAbUQMeta } from "/home/webpages/hochwacht-frontend/pages/documents.vue?macro=true";
import { default as donationkcWlL4seW0Meta } from "/home/webpages/hochwacht-frontend/pages/donation.vue?macro=true";
import { default as equipment7D6aCjnDG6Meta } from "/home/webpages/hochwacht-frontend/pages/equipment.vue?macro=true";
import { default as faqHA0kVmjb5IMeta } from "/home/webpages/hochwacht-frontend/pages/faq.vue?macro=true";
import { default as _91id_93Q4sMBZD7wlMeta } from "/home/webpages/hochwacht-frontend/pages/galleries/[id].vue?macro=true";
import { default as indexveA6RAgMU0Meta } from "/home/webpages/hochwacht-frontend/pages/galleries/index.vue?macro=true";
import { default as impressumwRf1uuCJJQMeta } from "/home/webpages/hochwacht-frontend/pages/impressum.vue?macro=true";
import { default as indexyrT9vPHYygMeta } from "/home/webpages/hochwacht-frontend/pages/index.vue?macro=true";
import { default as privacypolicyQcI97mphdZMeta } from "/home/webpages/hochwacht-frontend/pages/privacypolicy.vue?macro=true";
import { default as rentalKzT846CNYyMeta } from "/home/webpages/hochwacht-frontend/pages/rental.vue?macro=true";
import { default as rentalimagesWPoL8RGOu4Meta } from "/home/webpages/hochwacht-frontend/pages/rentalimages.vue?macro=true";
import { default as _91_46_46_46slug_93bjrTntsRLvMeta } from "/home/webpages/hochwacht-frontend/pages/resources/[...slug].vue?macro=true";
export default [
  {
    name: aboutwDaWN0gPCNMeta?.name ?? "about",
    path: aboutwDaWN0gPCNMeta?.path ?? "/about",
    meta: aboutwDaWN0gPCNMeta || {},
    alias: aboutwDaWN0gPCNMeta?.alias || [],
    redirect: aboutwDaWN0gPCNMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: _91id_93x60bGA2kemMeta?.name ?? "agegroups-id",
    path: _91id_93x60bGA2kemMeta?.path ?? "/agegroups/:id()",
    meta: _91id_93x60bGA2kemMeta || {},
    alias: _91id_93x60bGA2kemMeta?.alias || [],
    redirect: _91id_93x60bGA2kemMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/agegroups/[id].vue").then(m => m.default || m)
  },
  {
    name: contactjzGzDS0guuMeta?.name ?? "contact",
    path: contactjzGzDS0guuMeta?.path ?? "/contact",
    meta: contactjzGzDS0guuMeta || {},
    alias: contactjzGzDS0guuMeta?.alias || [],
    redirect: contactjzGzDS0guuMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: documentsw9Wj6wAbUQMeta?.name ?? "documents",
    path: documentsw9Wj6wAbUQMeta?.path ?? "/documents",
    meta: documentsw9Wj6wAbUQMeta || {},
    alias: documentsw9Wj6wAbUQMeta?.alias || [],
    redirect: documentsw9Wj6wAbUQMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: donationkcWlL4seW0Meta?.name ?? "donation",
    path: donationkcWlL4seW0Meta?.path ?? "/donation",
    meta: donationkcWlL4seW0Meta || {},
    alias: donationkcWlL4seW0Meta?.alias || [],
    redirect: donationkcWlL4seW0Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/donation.vue").then(m => m.default || m)
  },
  {
    name: equipment7D6aCjnDG6Meta?.name ?? "equipment",
    path: equipment7D6aCjnDG6Meta?.path ?? "/equipment",
    meta: equipment7D6aCjnDG6Meta || {},
    alias: equipment7D6aCjnDG6Meta?.alias || [],
    redirect: equipment7D6aCjnDG6Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/equipment.vue").then(m => m.default || m)
  },
  {
    name: faqHA0kVmjb5IMeta?.name ?? "faq",
    path: faqHA0kVmjb5IMeta?.path ?? "/faq",
    meta: faqHA0kVmjb5IMeta || {},
    alias: faqHA0kVmjb5IMeta?.alias || [],
    redirect: faqHA0kVmjb5IMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Q4sMBZD7wlMeta?.name ?? "galleries-id",
    path: _91id_93Q4sMBZD7wlMeta?.path ?? "/galleries/:id()",
    meta: _91id_93Q4sMBZD7wlMeta || {},
    alias: _91id_93Q4sMBZD7wlMeta?.alias || [],
    redirect: _91id_93Q4sMBZD7wlMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/galleries/[id].vue").then(m => m.default || m)
  },
  {
    name: indexveA6RAgMU0Meta?.name ?? "galleries",
    path: indexveA6RAgMU0Meta?.path ?? "/galleries",
    meta: indexveA6RAgMU0Meta || {},
    alias: indexveA6RAgMU0Meta?.alias || [],
    redirect: indexveA6RAgMU0Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/galleries/index.vue").then(m => m.default || m)
  },
  {
    name: impressumwRf1uuCJJQMeta?.name ?? "impressum",
    path: impressumwRf1uuCJJQMeta?.path ?? "/impressum",
    meta: impressumwRf1uuCJJQMeta || {},
    alias: impressumwRf1uuCJJQMeta?.alias || [],
    redirect: impressumwRf1uuCJJQMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: indexyrT9vPHYygMeta?.name ?? "index",
    path: indexyrT9vPHYygMeta?.path ?? "/",
    meta: indexyrT9vPHYygMeta || {},
    alias: indexyrT9vPHYygMeta?.alias || [],
    redirect: indexyrT9vPHYygMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacypolicyQcI97mphdZMeta?.name ?? "privacypolicy",
    path: privacypolicyQcI97mphdZMeta?.path ?? "/privacypolicy",
    meta: privacypolicyQcI97mphdZMeta || {},
    alias: privacypolicyQcI97mphdZMeta?.alias || [],
    redirect: privacypolicyQcI97mphdZMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/privacypolicy.vue").then(m => m.default || m)
  },
  {
    name: rentalKzT846CNYyMeta?.name ?? "rental",
    path: rentalKzT846CNYyMeta?.path ?? "/rental",
    meta: rentalKzT846CNYyMeta || {},
    alias: rentalKzT846CNYyMeta?.alias || [],
    redirect: rentalKzT846CNYyMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/rental.vue").then(m => m.default || m)
  },
  {
    name: rentalimagesWPoL8RGOu4Meta?.name ?? "rentalimages",
    path: rentalimagesWPoL8RGOu4Meta?.path ?? "/rentalimages",
    meta: rentalimagesWPoL8RGOu4Meta || {},
    alias: rentalimagesWPoL8RGOu4Meta?.alias || [],
    redirect: rentalimagesWPoL8RGOu4Meta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/rentalimages.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93bjrTntsRLvMeta?.name ?? "resources-slug",
    path: _91_46_46_46slug_93bjrTntsRLvMeta?.path ?? "/resources/:slug(.*)*",
    meta: _91_46_46_46slug_93bjrTntsRLvMeta || {},
    alias: _91_46_46_46slug_93bjrTntsRLvMeta?.alias || [],
    redirect: _91_46_46_46slug_93bjrTntsRLvMeta?.redirect,
    component: () => import("/home/webpages/hochwacht-frontend/pages/resources/[...slug].vue").then(m => m.default || m)
  }
]